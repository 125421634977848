import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";

class UserAgreement extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="User Agreement" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"User Agreement"} />
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <h3>1. Terms of Service</h3>
                    <p>
                      When you use our Services you agree to all of these terms
                      which includes our Privacy Policy, which covers how we
                      collect, use, share, and store your personal information.
                      You are entering into this Contract with Arcweight, Inc.
                      (also referred to as “Arcweight” “we” and “us”). You agree
                      that by registering, accessing or using our services
                      (described below), you are agreeing to enter into a
                      legally binding contract with Arcweight.
                    </p>
                    <p>
                      We may make changes to the Contract and will provide you
                      notice through our Services, and provide you the
                      opportunity to review the changes before they become
                      effective. Your continued use of our Services after we
                      publish or send a notice about our changes to these terms
                      means that you are consenting to the updated terms as of
                      their effective date.
                    </p>
                    <h3>2. Obligations</h3>
                    <p>
                      You represent and warrant you are eligible to enter into
                      this Contract and you are of “Legal Age.” You agree to use
                      a strong password and keep it secret. You agree that we
                      may provide notices and messages to you (i) within the
                      Service, or (ii) through the contact information you
                      provided us.
                    </p>
                    <p>
                      When you share information on our Services, others can
                      see, copy and use that information. Our Services allow
                      messaging and sharing of information in many ways and
                      where we have made settings available, we will honor the
                      choices you make about who can see content or information.
                      We are not obligated to publish any information or content
                      on our Service and can remove it with or without notice.
                    </p>
                    <p>
                      You agree that you will (i) comply with all applicable
                      laws, including, without limitation, privacy and
                      intellectual property laws, and (ii) provide accurate
                      information to us and keep it updated. You agree that you
                      will not (i) develop, support or use software, devices,
                      scripts, robots or any other means or processes (including
                      crawlers, browser plugins and add-ons or any other
                      technology) to scrape the Services or otherwise copy
                      profiles and other data from the Services, (ii) Override
                      any security feature or bypass or circumvent any access
                      controls or use limits of the Service, (iii) Copy, use,
                      disclose or distribute any information obtained from the
                      Services, whether directly or through third parties
                      without our consent, (iv) use our service to insert
                      software viruses, worms, or any other harmful code, (v)
                      reverse engineer, decompile, disassemble, decipher or
                      otherwise attempt to derive the source code for the
                      services or any related technology, (vi) Rent, lease,
                      loan, trade, sell/re-sell or otherwise monetize the
                      Services or related data or access to the same, without
                      our consent, or (vii) interfere with the operation of, or
                      place an unreasonable load on, the Services.
                    </p>
                    <h3>3. Rights and Limits</h3>
                    <h4>3.1 License</h4>
                    <p>
                      You own all of the content, feedback and personal
                      information you provide to us, but you also grant us a
                      non-exclusive license to it. We’ll honor the choices you
                      make about who gets to see your information and content.
                      As between you and Arcweight, you own the content and
                      information that you submit or post to the Services, and
                      you are only granting Arcweight the following
                      non-exclusive license: A worldwide, transferable and
                      sublicensable right to use, copy, modify, distribute,
                      publish and process, information and content that you
                      provide through our Services
                    </p>
                    <p>
                      You and Arcweight agree that if content includes personal
                      data, it is subject to our Privacy Policy and you agree
                      that we may access, store, process and use any information
                      and personal data that you provide in accordance with the
                      terms of our Privacy Policy.
                    </p>
                    <h4>3.2 Other</h4>
                    <p>
                      We may change, suspend or discontinue any of our Services.
                      Our service is currently free, but we may add or modify
                      our prices effective prospectively upon reasonable notice
                      to the extent allowed under the law. Arcweight reserves
                      the right to restrict, suspend, or terminate your account
                      if you breach this Contract or the law or are misusing the
                      Services. Arcweight reserves all of its intellectual
                      property rights in the Services. We use data and
                      information about you to make relevant suggestions to you
                      and others.
                    </p>
                    <h3>4. Warranty Disclaimers and Limit of Liability</h3>
                    <p>
                      ARCWEIGHT AND ITS AFFILIATES MAKE NO REPRESENTATION OR
                      WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION
                      THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND
                      PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION)
                      ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST
                      EXTENT PERMITTED UNDER APPLICABLE LAW, ARCWEIGHT AND ITS
                      AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY,
                      INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA,
                      NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
                      PARTICULAR PURPOSE.
                    </p>
                    <p>
                      IN NO EVENT SHALL ARCWEIGHT BE LIABLE FOR LOST PROFITS OR
                      LOST BUSINESS OPPORTUNITIES, REPUTATION, LOSS OF DATA OR
                      ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR
                      PUNITIVE DAMAGES OR IN ANY AMOUNT THAT EXCEEDS (A) THE
                      TOTAL FEES PAID OR PAYABLE BY YOU IF ANY, OR (B) US
                      $1,000.
                    </p>
                    <p>
                      THESE LIMITATIONS OF LIABILITY DO NOT APPLY TO LIABILITY
                      FOR DEATH OR PERSONAL INJURY OR FOR FRAUD, GROSS
                      NEGLIGENCE OR INTENTIONAL MISCONDUCT.
                    </p>
                    <h3>5. Termination</h3>
                    <p>
                      Both you and Arcweight may terminate this Contract at any
                      time with notice to the other. On termination, you lose
                      the right to access or use the Services. The following
                      shall survive termination:
                    </p>
                    <ul>
                      <li>Our rights to use and disclose your feedback;</li>
                      <li>
                        Your data; You must submit a request to delete data per
                        Privacy Policy;
                      </li>
                      <li>
                        Any amounts owed to Arcweight prior to termination;
                      </li>
                    </ul>
                    <h3>6. Governing Law and Dispute Resolution</h3>
                    <p>
                      You and Arcweight agree that the laws of the State of
                      California, U.S.A., excluding its conflict of laws rules,
                      shall exclusively govern any dispute relating to this
                      Contract and/or the Services. You and Arcweight both agree
                      that all claims and disputes can be litigated only in the
                      federal or state courts in San Mateo, California, USA, and
                      you and Arcweight each agree to personal jurisdiction in
                      those courts
                    </p>
                    <h3>7. Contact Information</h3>
                    <p>
                      For general inquiries, you may contact us via email
                      info@arcweight.com. For legal notices or service of
                      process, you may write us at: P.O. Box 924, Belmont, CA
                      94002.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default UserAgreement;
