import React, { Component, Fragment } from "react";
import Scrollspy from "react-scrollspy";
import Particles from "react-tsparticles";
import { FiX } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Arcweight",
    description: "Make your connections stronger with Arcweight.",
    buttonText: "Read More",
    buttonLink: "#about",
  },
];

class HomeParticles extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      //console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <Helmet pageTitle="ArcWeight" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-2"
                    src="/assets/images/logo/arc-weight-logo-2.png"
                    alt="ArcWeight"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "about", "team", "faqs"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#team">Team</a>
                  </li>
                  <li>
                    <a href="#faqs">FAQs</a>
                  </li>
                </Scrollspy>
              </nav>

              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}

        <div className="slider-activation with-particles" id="home">
          <div className="frame-layout__particles">
            <Particles
              className="particle"
              options={{
                style: {
                  position: "absolute",
                },
                fpsLimit: 100,
                interactivity: {
                  detectsOn: "canvas",
                  events: {
                    onClick: {
                      enable: true,
                      mode: "push",
                    },
                    onHover: {
                      enable: true,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    bubble: {
                      distance: 100,
                      duration: 2,
                      opacity: 0.8,
                      size: 10,
                      color: "#888",
                    },
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 100,
                      duration: 0.4,
                      color: "#888",
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#888",
                  },
                  links: {
                    color: "#888",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 6,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      value_area: 2000,
                    },
                    value: 80,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    random: true,
                    value: 5,
                  },
                },
                detectRetina: true,
              }}
            />
          </div>
          <div className="bg_color--7">
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title theme-gradient">
                            {value.title}
                          </h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-primary-color"
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--5" id="about">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <p className="description">
                        Making the right connections can make or break business
                        deals, job applications, and more. With hundreds or even
                        thousands of connections how can you find the ones that
                        count? We are preparing a solution to help you quickly
                        understand how to efficiently leverage your network to
                        make the right connections.
                      </p>
                      <p className="description">
                        Arcweight is a browser plug-in that makes LinkedIn more
                        useful by allowing you to rate the strength of your
                        connections, and to use those ratings to filter and sort
                        results in the searches you do every day. Arcweight also
                        makes it easy for you to ask your contacts to rate their
                        connections so you can efficiently identify the best
                        path to a shared contact. Ratings are quick/simple to
                        assign and are saved for future use. Arcweight is free
                        and we only store the names and ratings you provide.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        {/* Start Team Area  */}
        <div className="rn-team-area ptb--120 bg_color--1" id="team">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                  <h2 className="title">Team</h2>
                  <p>
                    Our team is a composed of several long term industry
                    veterans with a shared frustration of the LinkedIn
                    experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start FAQ Area  */}
        <div className="rn-faqs-area ptb--120 bg_color--5" id="faqs">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                  <h2 className="title">FAQs</h2>
                  <div className="faq">
                    <h4>What are rating definitions?</h4>
                    <p>
                      We have intentionally used simple, general words for the
                      three levels of connection strength Arcweight supports.
                      The interpretation of any simple labeling scheme will be
                      unavoidably subjective, but our intent with these labels
                      is roughly as follows:
                    </p>

                    <p>
                      <b>Strong</b>: These are your closest connections. Folks
                      you know well and trust. Folks who you would help and
                      would expect to help you if at all possible. Folks whose
                      opinions you value. In short, these are your “yes”
                      connections.
                    </p>

                    <p>
                      <b>Medium</b>: Medium connections aren’t strong or weak.
                      You know these folks to some extent and would probably
                      help and/or trust them more than an average person, but
                      your support may also vary widely based on the specific
                      situation. In short, these are your “maybe” connections.
                    </p>

                    <p>
                      <b>Weak</b>: These are new and/or more superficial
                      connections. These folks are probably wonderful in their
                      own right, but you don’t know them well at all and some of
                      them you may honestly struggle to remember. Because of
                      this, you probably wouldn’t prioritize helping them and
                      you probably wouldn’t trust them any more than an average
                      person. In short, these are your “not now” connections.
                    </p>
                  </div>

                  <div className="faq">
                    <h4>
                      I made a mistake or things have changed. How do I change a
                      rating?
                    </h4>

                    <p>
                      Simply re-rate the user and the new rating is saved. You
                      can do this whenever / as often as you want.
                    </p>
                  </div>
                  <div className="faq">
                    <h4>Will my connections see my ratings?</h4>

                    <p>
                      We only share your ratings with your connections that you
                      have rated as Medium or higher. We believe in privacy and
                      want to give you the ability to control who can see your
                      ratings.
                    </p>
                  </div>
                  <div className="faq">
                    <h4>How do permissions for shared ratings work?</h4>

                    <p>
                      Your rating for a given connection is only visible to the
                      user(s) you have rated as Medium or Strong. If you have
                      not rated or selecte a Weak rating that person will not be
                      able to see your other connection scores. Keep in mind,
                      though, that any user with whom you share a rating could
                      choose to reshare this rating. Arcweight doesn’t
                      facilitate or support this use, but we can’t prevent
                      informal sharing outside of Arcweight. Share with people
                      you trust!
                    </p>
                  </div>
                  <div className="faq">
                    <h4>
                      What if I rate my connection to User X differently than
                      User X rates their connection to me?
                    </h4>

                    <p>
                      This isn’t uncommon and it’s nothing to worry about.
                      Arcweight doesn’t allow a given user to request ratings
                      for themself from other users. Keep in mind, though, that
                      any user with whom you share a rating could choose to
                      share this rating.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End FAQ Area  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}
      </Fragment>
    );
  }
}
export default HomeParticles;
