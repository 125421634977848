import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";

class PrivacyPolicy extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Privacy Policy" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Privacy Policy"} />
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <h3>WHAT DOES THIS PRIVACY POLICY COVER?</h3>
                    <p>
                      This Privacy Policy applies to www.arcweight.com and the
                      Arcweight browser plugin available{" "}
                      <a
                        className="text-underline"
                        href="https://chrome.google.com/webstore/detail/arcweight/pmcponninanbcoemnkgddminngjceoco"
                      >
                        here
                      </a>
                      , collectively our “Products”. This Privacy Policy does
                      not apply to websites, applications or other services that
                      provide their own separate privacy statements or policies.
                      Our Products may contain links to other sites, but
                      Arcweight, Inc. (“Arcweight”) is not responsible for the
                      privacy policies and/or practices of these other sites.
                      Visitors to and users of our Products are referred to
                      individually as “User” and collectively as “Users.”
                    </p>
                    <p>
                      By visiting or using our Products, you expressly consent
                      to the information handling practices described in this
                      Privacy Policy.
                    </p>
                    <p>
                      This Privacy Policy covers what information we gather when
                      you are accessing or using our Products, how we use that
                      information and how we protect it. Your use of our
                      Products and information you provide on our Products is
                      subject to the terms of this Privacy Policy.
                    </p>
                    <h3>MINOR'S PRIVACY</h3>
                    <p>
                      Our service is not directed at children under the age of
                      18. Where we collect information of children under the age
                      of 18 years of age, parents or guardians of these
                      individuals will be required to consent to our collection
                      and use of this information in order for us to deliver
                      services to the individual. For more information about the
                      confirmation process, or how to access your child’s
                      personal data, please email us: info@arcweight.com. Other
                      than as noted, our use of personal information applies to
                      anyone using our Products regardless of age. If you are a
                      parent or guardian of an individual under 18, please be
                      sure to read this Privacy Policy thoroughly.
                    </p>
                    <h3>COLLECTION AND USE OF INFORMATION</h3>
                    <p>
                      We gather various types of information from users of our
                      Products, as explained more fully below. Some of this
                      information is provided directly by you and other
                      information may be gathered by observing how you interact
                      with our Products. We use this information to personalize
                      and improve our services and Products, and as otherwise
                      set forth in this Privacy Policy.
                    </p>
                    <h4>Information You Provide</h4>
                    <p>
                      We receive and store any information provided directly by
                      you. For example, we collect personal information such as
                      name, LinkedIn Identifier, and email address, when
                      provided to us through our Products or when you email our
                      support team directly. We also collect, store and use any
                      Connection Strength info you enter.
                    </p>
                    <p>
                      While you can choose not to provide certain information to
                      us, please know that this may result in our being unable
                      to register you or you may be unable to take advantage of
                      some of our features and services.
                    </p>
                    <p>
                      To remove or update any personal information, please
                      remove or modify your personal information within our
                      Products, although you may be unable to use our services
                      without our having certain information.
                    </p>
                    <h4>Information Collected Automatically from You</h4>
                    <p>
                      Whenever you interact with our Products, we automatically
                      receive and record certain information. This information
                      may include server logs from your browser including your
                      IP address, browser type, internet service provider,
                      referring/exit pages, operating system, date/time stamp,
                      “cookie” information, and the page you requested.
                    </p>
                    <p>
                      Technologies such as cookies, beacons, tags and scripts
                      are used by us and our partners, affiliates, analytics or
                      service providers. These technologies are used in
                      analyzing trends, administering our Website, tracking
                      users’ movements around our Website and to gather
                      information about our user base as a whole. We may receive
                      reports based on the use of these technologies by these
                      companies on an individual as well as aggregated basis.
                      “Cookies” are small text files stored on a user’s computer
                      or mobile device that allow us to recognize your browser
                      or mobile device and tell us how and when pages on our
                      Website are visited and by how many people. You may be
                      able to change the preferences on your browser or mobile
                      device to prevent or limit your computer or device’s
                      acceptance of cookies, but this may limit you from taking
                      advantage of some of our Website’s features.
                    </p>
                    <p>
                      Also, if you click on a link to a third-party website,
                      such third-party, for example marketing partners or
                      analytics service providers, may also transmit cookies to
                      you. This Privacy Policy does not cover the use of cookies
                      by any third-parties.
                    </p>
                    <p>
                      When you use our Services, we automatically collect
                      information on the type of device you use, operating
                      system version, and the device identifier (or "UDID"). We
                      do not ask for, access or track any location-based
                      information from your mobile device at any time while
                      using our Services.
                    </p>
                    <h3>EMAIL AND OTHER COMMUNICATIONS</h3>
                    <p>
                      We may contact you, by email or other means as you have
                      indicated, to send you information about our products and
                      services, including updates, enhancements, or other
                      related information or to communicate with you about your
                      use of our Website.
                    </p>
                    <p>
                      Arcweight may also send email on your behalf as directed
                      by you.
                    </p>
                    <h3>WILL Arcweight SHARE MY PERSONAL INFORMATION?</h3>
                    <p>
                      We neither rent, sell nor share your personal information
                      with anyone other than as set forth in this Policy.
                    </p>
                    <p>
                      We employ third parties (other companies and people) to
                      perform certain tasks on our behalf. These may include use
                      of a payment processor or hosting provider. This may
                      require that we share your information with them in order
                      for us to provide products or services you request. Unless
                      we tell you differently, our agents do not have any right
                      to use the personal information we share with them beyond
                      what is necessary to assist us.
                    </p>
                    <p>
                      We may access, read, preserve, and disclose any
                      information that we reasonably believe is necessary to
                      comply with applicable law or court order; to enforce our
                      rights or conditions of use and other agreements; or to
                      protect the rights, property, or safety of Arcweight, our
                      employees, our users, or others. This includes exchanging
                      information with other companies and organizations for
                      fraud protection and credit risk reduction.
                    </p>
                    <h3>BUSINESS TRANSFERS</h3>
                    <p>
                      We may choose to buy or sell assets. In these types of
                      transactions, customer information is typically one of the
                      business assets that is transferred. Also, if we (or
                      substantially all of our assets) are acquired, or if we go
                      out of business, enter bankruptcy, or go through some
                      other change of control, personal information would be one
                      of the assets transferred to or acquired by a third party.
                      You will be notified via email and/or a prominent notice
                      on our Website of any change in ownership or use of your
                      personal information, as well as any choices you may have
                      regarding your personal information.
                    </p>
                    <h3>IS MY PERSONAL INFORMATION SECURE?</h3>
                    <p>
                      Arcweight uses reasonable precautions to protect personal
                      information in its possession from loss, misuse and
                      unauthorized access, disclosure, alteration and
                      destruction. For users of our Products, communications
                      between Arcweight’s site and a user’s web browser are
                      accomplished using, at a minimum, 128-bit Secure Sockets
                      Layer (“SSL”) encryption and various third-party security
                      certificates to protect information. While we endeavor to
                      protect the privacy of your account and other personal
                      information we hold, no method of transmission over the
                      Internet can guarantee complete security. As a result, we
                      cannot guarantee its absolute security despite using
                      commercially acceptable means to protect your personal
                      information.
                    </p>
                    <p>
                      Your account is protected by a password for your privacy
                      and security. You are responsible for keeping this
                      information secure in order to prevent unauthorized access
                      to your account and Personal Information.
                    </p>
                    <h3>WHAT PERSONAL INFORMATION CAN I ACCESS?</h3>
                    <p>
                      Through your account settings, you may access, and, in
                      some cases, edit or delete the following information:
                    </p>

                    <ul>
                      <li>name and password;</li>
                      <li>email address;</li>
                      <li>user preferences;</li>
                      <li>connection ratings;</li>
                    </ul>

                    <p>
                      The information you can view, update, and delete may
                      change as the Products change. If you have any questions
                      about viewing or updating your information, please contact
                      us through our contact form. We will respond to your
                      request to access personal information on file with us
                      within a reasonable period of time not greater than 30
                      days. Please note that you will also be required to
                      authenticate your identity in order to obtain access to
                      any personal information.
                    </p>
                    <h3>WHAT CHOICES DO I HAVE?</h3>
                    <p>
                      You can always choose not to disclose personal information
                      to us, but keep in mind some information may be needed to
                      register with us or to take advantage of our special
                      features.
                    </p>
                    <p>
                      You may be able to add, update, or delete information as
                      explained in the Information You Provide section above.
                    </p>
                    <p>
                      You may request deletion of your account by contacting us
                      through our contact form. Please note that some
                      information may remain in our systems after you request
                      deletion of the information from your account. We will
                      retain your information for as long as your account is
                      active or as needed to provide services. We will also
                      retain and use personal information as necessary to comply
                      with our legal obligations, resolve disputes, and enforce
                      our agreements.
                    </p>
                    <p>
                      You may request details of your Personal Information, and
                      where necessary, have it corrected. We reserve the right
                      to charge a reasonable fee to cover our administrative
                      costs for providing you with access to your personal data.
                      For more details, please contact us through our contact
                      form.
                    </p>
                    <h3>CHANGES TO THIS PRIVACY POLICY</h3>
                    <p>
                      We may amend or update this Privacy Policy from time to
                      time and any such changes or updates will be posted to
                      this Privacy Policy, our Products or other place we deem
                      appropriate so that you are informed as to what
                      information we collect, how we use that information, and
                      under what circumstances we may share that information. If
                      we make material changes in the way we use Personal
                      Information, we will notify you by posting an announcement
                      on our Website or sending you an email prior to the change
                      becoming effective.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default PrivacyPolicy;
