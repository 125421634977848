// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import HomeParticles from './home/HomeParticles';

// Element Layout
import PrivacyPolicy from "./elements/PrivacyPolicy";
import UserAgreement from "./elements/UserAgreement";
import About from "./elements/About";
import Contact from "./elements/Contact";
import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeParticles} />

                        {/* Element Layout */}
                        <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy} />
                        <Route exact path={`${process.env.PUBLIC_URL}/user-agreement`} component={UserAgreement} />
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />

                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                        <Route component={error404} />

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();